<template>
  <div class="app-container">
    <search-header :query="query" @search="searchChange" @refreshPage="refreshPage" />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="finalData">
      <template #updateTimeSlot="{ scoped: row }">{{ parseTime(row.time) }} </template>
      <el-table-column fixed="right" label="操作" width="200px" align="center">
        <template slot-scope="{ row, $index }">
          <!-- <div v-if="row.isEnabled"> -->
          <color-text-btn v-p="['externaladmin:financialManagement:bankCategory:edit']" @click="onEdit(row)"
            >编辑</color-text-btn
          >
          <!-- <el-divider direction="vertical"></el-divider> -->
          <!-- </div> -->
          <!-- <el-popover placement="top" :ref="`popover-${$index}`" width="200" trigger="click">
            <slot name="tip">
              <p style="font-weight: 500; font-size: 16px">提示</p>
              <p v-if="row.isEnabled">您确定要启用吗？</p>
              <p v-else>您确定要禁用吗？</p>
            </slot>
            <div style="text-align: right">
              <el-button size="mini" @click="onCancel($index, row)">取消</el-button>
              <el-button
                :loading="row.loading"
                size="mini"
                type="primary"
                @click="onConfirm(row.isEnabled, row, $index)"
              >
                确定
              </el-button>
            </div>
            <template v-p="['externaladmin:financialManagement:bankCategory:disable']">
              <color-text-btn v-if="row.isEnabled" :loading="row.loading" slot="reference">启用</color-text-btn>
              <color-text-btn v-else :loading="row.loading" slot="reference">禁用</color-text-btn>
            </template>
          </el-popover> -->
        </template>
      </el-table-column>
    </CommonTable>
    <editDialog ref="edit" :form.sync="form" type="edit" />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import searchHeader from './module/header'
import editDialog from './module/addOrEditBankDialog.vue'
import { bankCols as cols } from './module/cols'
import { parseTime } from '@/utils'
import { initDataMixin } from '@/mixins'

export default {
  components: { searchHeader, editDialog },
  mixins: [initDataMixin],
  data() {
    return {
      url: '/externaladmin/systemService/systemConfig/getAccountSettings',
      cols,
      query: {
        companyName: ''
      },
      form: {
        bankName: ''
      },
      bankData: {},
      finalData: [{}]
    }
  },
  methods: {
    parseTime,
    onEdit(data) {
      this.bankData = data
      //重置form中bankName的值
      this.$refs.edit.visible = true
    },
    // setLoadingFalse(row, index) {
    //   row.loading = false
    //   this.$refs[`popover-${index}`].doClose()
    // },
    // async onConfirm(state, row, index) {
    //   this.$set(row, 'loading', true)
    //   const message = state ? '启用成功' : '禁用成功'
    // const { description, isEnabled } = row
    // try {
    //调用接口，如果code == 0则继续
    // let { code } = await editStyle({
    //   description,
    //   id: row.id,
    //   isEnabled: !isEnabled
    // })
    // if (code !== 0) return
    //     this.setLoadingFalse(row, index)
    //     this.init()
    //     this.$message.success(`${message}`)
    //   } catch (error) {
    //     this.setLoadingFalse(row, index)
    //   }
    // },
    // onCancel(index, row) {
    //   this.setLoadingFalse(row, index)
    //   this.$message.warning('您取消了操作')
    // },
    // onOperate(operate, data) {
    //   const { id } = data
    //   //调用启用/禁用接口
    //   this.init()
    //   const message = operate == 'open' ? '启用成功' : '禁用成功'
    //   this.$message.success(message)
    // },
    initCallBack() {
      this.data.forEach((v) => {
        const key = v.configCode
        this.$set(this.finalData[0], key, v.configValue)
      })
    },
    refreshPage() {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped></style>
