<template>
  <BaseDialog width="25vw" :title="title" :dialogVisible.sync="visible">
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="分类名称：" prop="bankName">
        <el-input style="width: 250px" size="small" v-model="form.bankName"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <loadingBtn type="primary" @click="doSubmit">确 定</loadingBtn>
    </span>
  </BaseDialog>
</template>

<script>
import { deepClone, getDiffData } from '@/components/avue/utils/util'

export default {
  props: {
    type: {
      type: String,
      default: 'add'
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    title() {
      return this.type == 'add' ? '新增银行分类' : '修改银行分类'
    }
  },
  data() {
    return {
      visible: false,
      rules: {
        bankName: [{ required: true, message: '银行分类不能为空', trigger: 'blur' }]
      },
      formCopy: {}
    }
  },
  watch: {
    visible(val) {
      if (val) this.formCopy = deepClone(this.form)
      else this.form.bankName = ''
    }
  },
  methods: {
    cancel() {
      this.visible = false
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate()
      } catch (error) {
        return false
      }
      const data = {
        companyName: this.form.companyName
      }
      if (!getDiffData(this.form, this.formCopy)) {
        this.visible = false
        this.$message.success('成功修改银行分类')
        return
      }
      try {
        //根据是添加还是编辑来调用接口
        const code = 200
        const message = this.type == 'add' ? '成功新增银行分类' : '成功修改银行分类'
        if ($SUC({ code })) {
          this.visible = false
          this.$listeners.refreshPage()
          this.$message.success(message)
        }
      } catch (error) {}
    }
  }
}
</script>

<style lang="sass" scoped></style>
