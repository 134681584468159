<template>
  <div>
    <el-form :inline="true">
      <el-button
        v-p="['externaladmin:financialManagement:bankCategory:add']"
        type="primary"
        icon="el-icon-plus"
        class="add-btn"
        @click="onAdd"
        >新增银行分类</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </el-form>
    <addDialog ref="addDialog" :sup_this="sup_this" :form.sync="form" v-on="$listeners" />
    <div class="search-warpper formPart" v-p="['externaladmin:financialManagement:bankCategory:search']">
      <el-form :inline="true" :model="query">
        <el-form-item label="开户银行：">
          <el-select
            size="mini"
            @change="$emit(`search`)"
            style="width: 200px"
            v-model="query.bankId"
            clearable
            placeholder="请选择开户银行"
          >
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
            type="primary"
            @click="$emit(`search`)"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import addDialog from './addOrEditBankDialog.vue'
export default {
  components: { addDialog },
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [],
      visible: false,
      form: {
        bankName: ''
      },
      sup_this: this
    }
  },
  methods: {
    onAdd() {
      this.$refs.addDialog.visible = true
    }
  },
  created() {
    //请求调用接口，将detail赋值给options
  }
}
</script>

<style lang="scss" scoped>
.add-btn {
  height: 28px;
  padding: 0 10px;
  line-height: 28px;
}
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .el-form-item {
    margin-right: 20px;
  }
}
</style>
